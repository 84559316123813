<template>
  <b-modal
    id="bpa-sus-guide-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="closeModal"
    no-close-on-backdrop
    no-close-on-esc 
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="localSusGuide?.id">
          Atualizar guia BPA-I (Boletim de Produção Ambulatorial Dados Individualizados) - N° {{ localSusGuide?.guide?.guide_number }}
        </span>
        <span v-else>
          Nova guia BPA-I (Boletim de Produção Ambulatorial Dados Individualizados) - N° {{ sus_information.current_bpa_guide_number + 1 }}
        </span>
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <div class="around-content">
      <!-- Estabelecimento -->
      <b-row>
        <b-col class="mt-3" cols="12">
          <p class="form-title mb-3">Identificação do estabelecimento de saúde (Solicitante)</p>
        </b-col>
        <b-col class="mt-3" cols="10">
          <b-form-group>
            <label for="name_establishment">Nome do estabelecimento de saúde</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="clinic.name"
              :readonly="true"
              :state="errors?.name_establishment ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.name_establishment">
              {{ errors.name_establishment }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="cnaes_code">CNES</label>
            <b-form-input
              id="cnaes_code"
              v-mask="'#######'"
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="sus_information.cnaes_code"
              :readonly="true"
              :state="errors?.cnaes_code ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.cnaes_code">
              {{ errors.cnaes_code }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <!-- Profissional -->
      <b-row class="mt-3">
        <b-col class="mt-3" cols="12">
          <p class="form-title mb-3">Identificação do Profissional</p>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="professional_cns">Cartão Nacional de Saúde (CNS)</label>
            <b-form-input
              placeholder="000000000000000"
              autocomplete="off"
              class="form-control"
              v-model="form.professional.cns"
              :readonly="true"
              :state="errors?.professional_cns ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.professional_cns">
              {{ errors.professional_cns }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="professional_name">Nome do profissional</label>
            <ProfessionalFieldModule
              :professional="form.professional"
              :clinic="clinic"
              :readonly="readonly"
              @update-professional="updateProfessional"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="professional_cbo">CBO</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.professional.cbo"
              :readonly="true"
              :state="errors?.professional_cbo ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.professional_cbo">
              {{ errors.professional_cbo }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="year_month">Ano/Mês</label>
            <date-picker
              placeholder="Selecionar"
              v-model="form.year_month"
              name="year_month"
              format="MM/YYYY"
              value-type="date"
              id="year_month"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :state="errors?.year_month ? false : null"
              :disabled="readonly"
            />
            <b-form-invalid-feedback v-if="errors?.year_month">
              {{ errors.year_month }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="4">
          <b-form-group>
            <label for="team">Equipe</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.team"
              :readonly="readonly"
              :state="errors?.team ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.team">
              {{ errors.team }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="sheet">Folha</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.sheet"
              :readonly="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <!-- Paciente -->
      <b-row class="mt-3">
        <b-col class="mt-3" cols="12">
          <p class="form-title mb-3">Identificação do Paciente (Sequência 1)</p>
        </b-col>  
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="professional_cns">Cartão Nacional de Saúde (CNS)</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.cns"
              :readonly="readonly"
              :state="errors?.patient_cns ? false : null"
            />
            <b-form-invalid-feedback v-if="errors?.patient_cns">
              {{ errors.patient_cns }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>      
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="patient_name">Nome do paciente</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.name"
              :readonly="readonly"
              :state="errors?.patient_name ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.patient_name">
              {{ errors.patient_name }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_gender">Sexo</label>
            <b-form-radio-group
              id="patient_gender"
              v-model="form.patient.gender"
              name="patient_gender"
              class="pt-2"
              :disabled="readonly"
            >
              <b-form-radio value="M">Masc.</b-form-radio>
              <b-form-radio value="F">Fem.</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_birthday">Data de nascimento</label>
            <b-form-input
              v-model="form.patient.birthday"
              id="patient_birthday"
              name="patient_birthday"
              class="full"
              type="date"
              :readonly="readonly"
              :state="errors?.date_birth_bpa ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.date_birth_bpa">
              {{ errors.date_birth_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_nationality">Nacionalidade</label>
              <multiselect
                id="nationality"
                autocomplete="off"
                v-model="form.patient.nationality"
                :options="nationalities"
                label="value"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                placeholder="Selecione um país"
                class="with-border"
                :disabled="readonly"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
            <b-form-invalid-feedback v-if="errors?.nationality_bpa">
              {{ errors.nationality_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_ethnicity">Raça/Cor</label>
              <multiselect
              id="state"
              v-model="form.patient.ethnicity"
              :options="ethnicityOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="errors?.race_color_bpa ? false : null">
              {{ errors.race_color_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_ancestry">Etnia</label>
              <multiselect
              id="state"
              v-model="form.patient.ancestry"
              :options="ancestryOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              placeholder="Selecionar"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback :state="errors?.ethnicity_bpa ? false : null">
              {{ errors.ethnicity_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_zipcode">CEP</label>
            <b-form-input
              v-mask="'#####-###'"
              placeholder="00000-000"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.zipCode"
              :readonly="readonly"
              :state="errors?.cep_bpa ? false : null"
              @keyup="searchCEP"
              />
            <b-form-invalid-feedback v-if="errors?.cep_bpa">
              {{ errors.cep_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_ibge_code">Cód. IBGE Município</label>
            <b-form-input
              v-mask="'#######'"
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.ibge_code"
              :readonly="readonly"
              :state="errors?.ibge_bpa ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.ibge_bpa">
              {{ errors.ibge_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_street_code">Cód. Logradouro</label>
            <b-form-input
              v-mask="'#######'"
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.street_code"
              :readonly="readonly"
              :state="errors?.street_code_bpa ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.street_code_bpa">
              {{ errors.street_code_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address">Logradouro</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.address"
              :readonly="readonly"
              :state="errors?.address_bpa ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.address_bpa">
              {{ errors.address_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="2">
          <b-form-group>
            <label for="patient_address_number">Número</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              ref="addressNumber"
              class="form-control"
              v-model="form.patient.address.number"
              :readonly="readonly"
              :state="errors?.number_bpa ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.number_bpa">
              {{ errors.number_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="5">
          <b-form-group>
            <label for="patient_address_number">Complemento</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.complement"
              :readonly="readonly"
              :state="errors?.complement_bpa ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.complement_bpa">
              {{ errors.complement_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="5">
          <b-form-group>
            <label for="patient_address_neighborhood">Bairro</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.neighborhood"
              :readonly="readonly"
              :state="errors?.neighborhood_bpa ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.neighborhood_bpa">
              {{ errors.neighborhood_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address_city">Município</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.address.city"
              :readonly="readonly"
              :state="errors?.neighborhood_bpa ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.neighborhood_bpa">
              {{ errors.neighborhood_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="6">
          <b-form-group>
            <label for="patient_address_state">Estado</label>
            <multiselect
              id="addressState"
              v-model="form.patient.address.state"
              :options="statesOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              :allowEmpty="false"
              placeholder="UF"
              class="with-border"
              :disabled="readonly"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <b-form-invalid-feedback v-if="errors?.state_apac">
              {{ errors.state_apac }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="3">
          <b-form-group>
            <label for="patient_phone_number">Telefone de contato</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.cellphone"
              :readonly="readonly"
              :state="errors?.contact_telephone_bpa ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.contact_telephone_bpa">
              {{ errors.contact_telephone_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="mt-3" cols="9">
          <b-form-group>
            <label for="patient_address_neighborhood">E-mail</label>
            <b-form-input
              placeholder="Descrever"
              autocomplete="off"
              class="form-control"
              v-model="form.patient.email"
              :readonly="readonly"
              :state="errors?.e_mail_bpa ? false : null"
              />
            <b-form-invalid-feedback v-if="errors?.e_mail_bpa">
              {{ errors.e_mail_bpa }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-col cols="12">
        <hr class="hr"/>
      </b-col>

      <ProceduresPerformed
        :checkedItems="procedures"
        :readonly="!readonly"
        :clinicHealthPlanId="sus_information?.clinic_health_plan?.id"
        @update-procedures="updateProcedures"
        :requestFields="requiredField"
        :type="'bpa'"
      />

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-2"
          variant="outline-primary"
          outline
          v-if="localSusGuide?.id"
          @click="print"
        >
          Imprimir guia
        </b-button>

        <b-button
          variant="primary"
          size="lg"
          @click="updateSusGuide"
          :disabled="localSusGuide?.situation === 'Cancelada'"
          v-if="localSusGuide && localSusGuide.id && !readonly"
        >
          Atualizar guia
        </b-button>
        <b-button
          variant="primary"
          size="lg"
          @click="createGuide"
          v-else-if="!readonly"
        >
          Salvar guia
        </b-button>
      </div>

    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import ProceduresPerformed from './Modules/ProceduresPerformed.vue';
import { SusBatch } from '@/modules/sus/manageGuides/utils/statuses'
import { saveAs } from 'file-saver'
import api from '@/modules/sus/api'
import moment from 'moment';
import * as cep from 'cep-promise'

export default {
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down-select.svg'),
    ProfessionalFieldModule: () => import('./Modules/ProfessionalFieldModule'),
    ProceduresPerformed,
  },
  props: {
    sus_information_setting: Object,
    patient: {
      type: Object,
      required: true,
    },
    clinicHealthPlan: {
      type: Object
    },
    susGuide: {
      type: Object,
      default: () => ({})
    },
    checkedItems: Array,
    appointmentId: String,
    clinicHealthPlanId: String
  },
  data() {
    return {
      user: getCurrentUser(),
      clinic: getCurrentClinic(),
      form: this.getDefaultForm(),
      localSusGuide: null,
      loading: true,
      procedures: [],
      readonly: this.isReadonly(),
      requiredField: [],
      sus_information: {},
      errors: {},
      nationalities: [],
      statesOptions: [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO'
      ],
      ancestryOptions:[
        'Africana',
        'Asiática',
        'Latina',
        'Indígena',
        'Árabe',
        'Eslava',
        'Judaica',
        'Romani'
      ],
      ethnicityOptions: [
        'Branca',
        'Preta',
        'Parda',
        'Amarela',
        'Indígena',
      ],
      langDatePicker: {
        formatLocale: {
          months: moment.months(),
          monthsShort: moment.monthsShort(),
        },
      },
    };
  },
  async mounted() {
    try {
      const { data } = await api.getInformationSusSettingByClinicId(this.clinic.id);
      this.sus_information = data;

      this.getRequiredFields();
    
      if (this.nationalities.length === 0) {
        this.api.getNationalities().then(async res => {
          this.nationalities = res.data;
        })
      }

    } catch (error) {
      console.error('Erro ao carregar dados do SUS', error);
    }
  },
  methods: {
    isReadonly() {
      this.readonly = Boolean(
        this.localSusGuide?.id &&
        (this.localSusGuide.sus_batch !== null && this.localSusGuide?.sus_batch?.status !== SusBatch.BATCH_STATUS_TO_SENT) && 
        ['Finalizada'].includes(this.localSusGuide?.situation)
      );    
    },
    getCheckedItems(){
      if(this.checkedItems){
        this.procedures = this.checkedItems.map((item, index) => ({
          id: null,
          item: item.item,
          item_id: item.item_id,
          code: item.item.code,
          name: item.item.name,
          attendance_date: moment(item?.appointment?.start_datetime).toDate(),
          quantity: 1,
          appointment_item_id: item.id,
          unitary_value: item.item.health_plan_props.value,
          total_value: item.item.health_plan_props.value,
          is_principal: index === 0
        }));
      }
    },
    getDefaultForm(){
      return {
        professional: { 
          ...this.professional || 
          {
            cns: '',
            cbo: '',
            name: '',
          }
        },
        patient: {
            cns: '',
            name: '',
            gender: '',
            birthday: '',
            email: '',
            nationality: null,
            ethnicity: null,
            ancestry: null,
            address: {
              zipCode: '',
              ibge_code: '',
              street_code: '',
              address: '',
              number: '',
              complement: ''
            }
        },
        year_month: null,
        team: '',
        sheet: '',
        procedures: [],
      }
    },
    async getRequiredFields() {
      try {
        const requiredfield = {};
        if (this.clinic.id) {
          const { data } = await api.getSusGuideRequiredFields(this.clinic.id);   
          
          Object.keys(data).map(field => {
            if(field.includes("bpa") && data[field] === true){
              requiredfield[`${field}`] = true;
            }
          });
        }

        this.requiredField = requiredfield;        
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onShow() {
      this.loading = false;
      this.isReadonly();
      
      if(this.susGuide && Object.values(this.susGuide).length > 1){
        this.updateLocalSusGuide(this.susGuide);  
      }
      
      if(this.patient && Object.values(this.patient).length > 1){
        this.updateLocalPatient(this.patient);
      }

      this.updateNationalityPatient(this.patient);
      this.getCheckedItems();      
    },
    closeModal() {
      this.form = this.getDefaultForm();
      this.localSusGuide = {};
      this.procedures = [];
      this.form.procedures = [];
      this.$emit('onHide');
      this.$bvModal.hide('bpa-sus-guide-modal');
    },
    searchCEP() {
      if (this.form.patient?.address?.zipCode.length === 9) {
        cep(this.form.patient.address.zipCode)
          .then(res => {
            this.form.patient.address.address = res.street
            this.form.patient.address.neighborhood = res.neighborhood
            this.form.patient.address.state = res.state
            this.form.patient.address.city = res.city	
            this.$refs.addressNumber.focus()
          })
          .catch(err => {
            console.log('Err', err)
          })
      }
    },
    isValidForm() {
      const errors = {};
      
      if(!this.form?.procedures){        
        this.$toast.error('Obrigatório incluir procedimento a guia!');
        return true;
      }

      if(this.requiredField.name_establishment_bpa && !this.clinic.name){
        errors.name_establishment = "Campo obrigatório";      
      }

      if(this.requiredField.cnes_bpa && !this.sus_information.cnaes_code) {
        errors.cnaes_code = "Campo obrigatório";
      }
      
      if(this.requiredField.national_card_bpa){
        if (!this.form.professional.cns) {
          errors.professional_cns = "Campo obrigatório";
        }
        if (!this.form.patient.cns) {
          errors.patient_cns = "Campo obrigatório";
        }        
      }
      
      if(this.requiredField.cbo_bpa && !this.form.professional.cbo) {
        errors.professional_cbo = "Campo obrigatório";
      }
      
      if(this.requiredField.year_month_bpa && !this.form.year_month) {
        errors.year_month = "Campo obrigatório";
      }

      if(this.requiredField.team_bpa && !this.form.team) {
        errors.team = "Campo obrigatório";
      }

      if(this.requiredField.patient_name_bpa && !this.form.patient.name){
        errors.patient_name = "Campo obrigatório";
      }

      if(this.requiredField.date_birth_bpa && !this.form.patient.birthday) {
        errors.date_birth_bpa = "Campo obrigatório";
      }       
      
      if(this.requiredField.nationality_bpa && !this.form.patient.nationality) {
        errors.nationality_bpa = "Campo obrigatório";
      }
      
      if(this.requiredField.race_color_bpa && !this.form.patient.ethnicity){
        errors.race_color_bpa = "Campo obrigatório";
      }
      
      if(this.requiredField.ethnicity_bpa && !this.form.patient.ancestry){
        errors.ethnicity_bpa = "Campo obrigatório";
      }
      
      if(this.requiredField.cep_bpa && !this.form.patient.address.zipCode){
        errors.cep_bpa = "Campo obrigatório";
      }
      
      if (this.requiredField.ibge_bpa && !this.form.patient.address.ibge_code){
        errors.ibge_bpa = "Campo obrigatório";
      }

      if (this.requiredField.street_code_bpa && !this.form.patient.address.street_code){
        errors.street_code_bpa = "Campo obrigatório";
      }

      if (this.requiredField.number_bpa && !this.form.patient.address.number) {
        errors.number_bpa = "Campo obrigatório";
      }

      if (this.requiredField.complement_bpa && !this.form.patient.address.complement) {
        errors.complement_bpa = "Campo obrigatório";
      }

      if (this.requiredField.neighborhood_bpa && !this.form.patient.address.neighborhood) {
        errors.neighborhood_bpa = "Campo obrigatório";
      }

      if (this.requiredField.contact_telephone_bpa && !this.form.patient.cellphone) {
        errors.contact_telephone_bpa = "Campo obrigatório";
      }

      if (this.requiredField.e_mail_bpa && !this.form.patient.email) {
        errors.e_mail_bpa = "Campo obrigatório";
      }

      if (this.requiredField.address_bpa) {
        if (!this.form.patient.address.zipCode) {
          errors.patient_address_zipcode = "Campo obrigatório";
        }
      }  

      this.errors = errors;

      return Object.keys(errors).length;
    },
    getPayload(){
      const payload = {
        sus_information_id: this.sus_information.id,
        guide_number: this.sus_information.current_bpa_guide_number,
        clinic_health_plan_id: this.sus_information.clinic_health_plan.id,
        clinic_id: this.clinic.id,
        professional_id: this.form.professional.id,
        year_month: this.form.year_month,
        team: this.form.team,
        sheet: this.form.sheet,
        patient_id: this.form.patient.id,
        address: this.form.patient.address,
        procedures: this.form.procedures,
        guide_type: "BPA",
        appointment_id: this.appointmentId,
        patient: {
          ...this.form.patient,
          nationality: this.form.patient.nationality?.value,
          nationality_id: this.form.patient.nationality?.id
        },
      };

      return payload;
    },
    async createGuide() {      
      if (this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      
      const isLoading = this.$loading.show()
      try {
        const payload = this.getPayload();
        
        const { data } = await api.createSusGuide(payload);
        
        this.$emit('refresh-tiss-guides', {
          id: data.id,
          guide_id: data.guide_id,
          isSus: true
        });

        this.$toast.success('Guia BPA criada com sucesso!')
        this.closeModal();        
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateSusGuide() {
      if (this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }
      const isLoading = this.$loading.show()
      try {
        const payload = this.getPayload();
        
        const { data } = await api.updateSusGuide(this.localSusGuide.id, payload);

        this.$emit('refresh-tiss-guides', {
          id: data.id,
          guide_id: data.guide_id,
          isSus: true
        });
        
        this.$toast.success('Guia BPA atualizada com sucesso!')
        this.closeModal();        
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateProfessional(professional) {
      this.form.professional = professional;
    },
    updateProcedures(procedures) {
      this.form.procedures = procedures;
    },
    async searchNationality(query) {
      if (query.length > 2) {
        this.nationalities = []
        try {
          const response = await this.api.searchNationality({ term: query })
          this.nationalities = response.data.map(nationality => {
            return {
              label: `${nationality.value}`,
              id: nationality.id
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },
    async print() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await api.printSusGuide(this.localSusGuide.id, getCurrentUser()?.name) 
        const blob = new Blob([data], { type: 'application/pdf' })
        saveAs(blob, 'Guia BPA')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    updateLocalSusGuide(susGuide) {
      if (Object.values(susGuide).length > 0) {
        this.localSusGuide = { ...susGuide };    
        
        const isLoading = this.$loading.show();
            
        this.form = {
          ...this.form,
          procedures: { ...this.localSusGuide?.guide?.procedures || [] },
          team: this.localSusGuide?.guide?.team,
          sheet: this.localSusGuide?.guide?.sheet,
          year_month: this.localSusGuide?.guide?.date ? moment(this.localSusGuide.guide.date).toDate() : null,
          professional: this.localSusGuide?.professional || {},
          patient: this.localSusGuide?.patient || {},
        };
        
        this.updateNationalityPatient(this.form.patient);

        if(this.localSusGuide?.professional?.sus_professional_setting){
          this.form.professional.cns = this.localSusGuide.professional.sus_professional_setting.cns;
          this.form.professional.cbo = this.localSusGuide.professional.sus_professional_setting.cbo;
        }

        if(this.localSusGuide?.guide?.procedures){
          this.procedures = this.localSusGuide.guide?.procedures.map(item => ({
              'id': item.id,
              'item': item.item,
              'item_id': item.item_id,
              'code': item.item.code,
              'name': item.item.name,
              'attendance_date': moment(item.attendance_date).toDate(),
              'quantity': item.quantity,
              'appointment_item_id': item.appointment_id,
              'total': item.total_value,
              'unitary_value': item.unitary_value,
              'is_principal': false,
              'service': item.service,
              'class': item.class,
              'cid': {
                ...item.cid,
                label: item.cid ? (item.cid.code + ' - ' + item.cid.description) : ''
              },
              'attendance_character': item.attendance_character,
              'authorization_number': item.authorization_number
          
          }));
        }
        
        isLoading.hide();
        this.loading = false;
      }    
    },
    updateLocalPatient(patient) {
      this.form.patient = {
        ...patient,
        address: patient.address || {
          zipCode: '',
          ibge_code: null,
          street_code: null,
          address: '',
          complement: '',
          neighborhood: ''
        }
      };

      this.updateNationalityPatient(patient);
    },
    updateNationalityPatient(patient) {
      if(patient.nationality && typeof patient.nationality === "string"){
        const nationalityText = patient.nationality ? patient.nationality : "BRASIL";
        
        this.form.patient.nationality = this.nationalities.find(el => {
          return el.value === nationalityText
        });
      }
    }
  },
  watch: {
    susGuide: {
      handler(newVal) {
          if (newVal) {
            this.updateLocalSusGuide(newVal);
          }
        },
        immediate: true,
        deep: true
    },
    patient: {
      handler(patient) {
        if (patient) {
          this.updateLocalPatient(patient);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#bpa-sus-guide-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;

      .multiselect {
        color: var(--type-active) !important;
      }
    }

    .wh-button {
      color: var(--blue-500);
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  .width-80 {
    width: 80%;
  }

  .form-title {
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }

  .hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .check-disable {
    background-color: var(--neutral-100) !important;
    border: 1px solid var(--neutral-300) !important;
  }

  .invalid-feedback{
    display: block;
  }
}
</style>
